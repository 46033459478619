<template>
  <div class="">
    <van-overlay z-index="100" :show="show" :custom-style="{ background: 'rgba(0, 0, 0, 0.8)' }">
      <div class="gzhPopup">
        <div class="popup">
          <div class="bz gzhTopBgk">
            <img class="gzhPopup" src="../../../assets/img/yqPreheat/gzhPopup.png" alt="">
          </div>
          <div class="bz">
            <div class="bzTitle">步骤一：复制下方口令</div>
            <div class="bzFont">
              <div class="font1">
                <div class="fontName">口令：</div>
                <div class="fontPassword">{{ code }}</div>
              </div>
              <div class="copyBtn" ref="copy" @click="copy">
                复制
              </div>
            </div>
          </div>
          <div class="bz">
            <div class="bzTitle">步骤二：{{ nameFont }}</div>
            <div class="bzTwo">
              <img v-if="type == 'join_official_account'" src="../../../assets/img/yqPreheat/ewm.png" alt="">
              <img v-if="type == 'join_group'" src="../../../assets/img/yqPreheat/sq.png" alt="">
              <img v-if="type == 'join_dodo'" src="../../../assets/img/yqPreheat/dodoCeshi.png" alt="">
              <div class="bz2_Font">长按保存二维码</div>
            </div>
          </div>
        </div>
        <div class="closeIcon" @click="close()">
          <img src="../../../assets/img/yqPreheat/close.png" alt="">
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  data() {
    return {
    };
  },
  props: ["show", 'nameFont', 'code', 'type'],
  computed: {
  },
  async mounted() {

  },
  methods: {
    close() {
      console.log(111)
      this.$emit("close");
    },
    copy() {
      let copy = this.$refs.copy;
      const clipBoard = new Clipboard(copy, {
        text: () => {
          return this.code;
        },
      });
      clipBoard.on("success", (e) => {
        console.log(e);
        this.$toast("复制成功");
      });
      clipBoard.on("error", (err) => {
        console.log(err);
        this.$toast.fail("复制失败");
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.closeIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 19%;
  left: 47%;

  img {
    width: 100%;
    height: 100%;
  }
}

.gzhPopup {
  // background: url(../../../assets/img/yqPreheat/gzhPopup.png) no-repeat;
  // background-size: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .popup {
    padding: 100px 15px 40px 25px;
    background: #fff;
    border-radius: 10px;
    position: relative;
    width: 80%;
    margin: auto;
    .gzhTopBgk{
      position: absolute;
      width: 100%;
      left: 0;
      top: -114px;
    }
    .bz {
      .gzhPopup{
        width: 100%;
        height: 240px;
      }
      .bzTitle {
        margin-bottom: 8px;
        font-weight: 700;
      }

      .bzFont {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .font1 {
          display: flex;
          align-items: center;

          .fontName {
            font-size: 12px;
          }

          .fontPassword {
            font-size: 12px;
            color: #9a9a9a;
          }
        }

        .copyBtn {
          padding: 5px 20px;
          background: #000;
          color: #fff;
          border-radius: 20px;
          margin-left: 20px;
        }
      }

      .bzTwo {
        font-size: 13px;
        text-align: center;
        padding: 30px 0 0;
        img {
          width: 100px;
          height: 100px;
          // margin: 20px 0 0px;
        }

        .bz2_Font {
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }

}</style>
