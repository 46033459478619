<template>
  <div class="mhUse">
    <van-overlay z-index="100" :show="show" @close="close()" :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }">
      <div class="use">
        <div class="useBgk">
          <div class="useBgkTop">
            <div class="useTop" @click="onShowContent('')">
              <img src="../../../assets/img/yqPreheat/send.png" alt="">
              <div>
                查看盲盒内容
              </div>
            </div>
            <div class="useMh">
              <img src="../../../assets/img/yqPreheat/mh1.png" alt="">
            </div>
          </div>
          <div class="useBgkBtm">
            <div class="useBtm">
              <div class="btmFont">
                当前盲盒数量:{{ mhNum }}
              </div>
              <div class="stepper">
                <div class="stepperFont">使用盲盒数量</div>
                <van-stepper v-model="seedNumber" min="1" :max="Number(mhNum)" />
              </div>
              <div class="classBtn">
                <div class="useBtn btn1" @click="close()">
                  取消
                </div>
                <div class="useBtn btn2" @click="onObtain()">
                  使用
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay z-index="100" :show="showObtain" @click="close()" :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }">
      <div class="obtion">
        <div class="obtionBgk">
          <div class="obtionTop">
            恭喜获得
          </div>
          <div class="obtionBtm">
            <div class="obtainList">
              <div class="obtainItem" v-for="(item, index) in lotteryContList" :key="index">
                <!-- <div class="itemImg">
                  <img :src="item.img" alt="">
                </div> -->
                <div class="itemFont">
                  {{ index.split('，')[0] }}*{{item}}
                </div>
              </div>
            </div>
            <div class="classBtn">
              <div class="useBtn btn1" @click="continues()">
                继续开
              </div>
              <div class="useBtn btn2" @click="onObtainClose()">
                收下奖励
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-popup class="popupBottom" v-model="showReward" @close="onPopupBottomClose()" position="bottom">
      <div class="rewards">
        <div class="reward">
          <div class="rewardIcon" @click="showReward = false">
            <van-icon name="arrow-left" size="19" />
          </div>
          <div class="rwardTitle">
            盲盒内容
          </div>
        </div>
        <div class="rwardList">
          <!-- <div class="rwardItem" v-for="(item, index) in obtainList" :key="index" @click="showDetail = true">
            <div class="itemFont">
              {{ item }}
            </div>
          </div> -->
          <template>
            <div class="rwardItem" v-for="(item, index) in rewardDataList" :key="index" @click="rwardFun(item)">
            <div class="itemImg">
              <img :src="item.img" alt="">
            </div>
            <div class="itemFont">
              {{item.text}}
            </div>
            <div class="sendIcon">
              <img src="../../../assets/img/yqPreheat/send.png" alt="">
            </div>
          </div>
          </template>
           
        </div>
      </div>

    </van-popup>
    <van-popup v-model="showDetail" :round="true" position="center">
      <div class="rewardDetail">
        <div class="sendIcon">
          <img src="../../../assets/img/yqPreheat/send.png" alt="">
        </div>
        <div class="font1" v-for="(item, index) in fontList" :key="index">
          {{fontList[index]}}
        </div>
        <!-- <div class="font1">购买姜小竹元气硬件后赠送价值￥100艾柱一套</div>
        <div class="font2">活动结束后，工作人员联系中奖用户验资发放。</div> -->
        <div class="font3">点击屏幕任意处退出</div>
      </div>
    </van-popup>
    <van-overlay :show="showLoading"  z-index="101" :custom-style="{ background: 'rgba(0,0,0,.5)' }">
      <div class="loading">
        <van-loading type="circular" color="#fff" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { rewardData } from "../common/reward.js";
import { open_box,vitality_blind_box } from "../../../api/yqPreheat";
export default {
  data() {
    return {
      rewardDataList : rewardData,
      residueMh: 0,//使用盲盒后剩余盲盒数量
      seedNumber: 1,//步进器
      showObtain: false, //是否显示获得奖励弹框
      showReward: false,//是否展示盲盒内容弹框
      showDetail: false,//是否展示盲盒内容详情弹框
      obtainList: [], //查看活动奖励内容
      fontList: [],
      lotteryContList: [],//查看单个中奖内容
      typeShowContent: '',//判断是否是首页点击查看盲盒内容
      showLoading: false//是否显示loading
    };
  },
  props: ["show", "mhNum"],
  computed: {
  },
  async mounted() {
  },
  methods: {
    rwardFun(e){
      this.fontList = e.ms
      console.log(112, this.fontList[0])

      this.showDetail = true
    },
    //查看整个活动奖励
    onShowContent(e){
      this.typeShowContent = e
      this.showReward = true
      this.onDateil();
    },
    //获取活动详情
    async onDateil() {
      try {
        const res = await vitality_blind_box({
          acctoken: localStorage.getItem('token')
        });
        this.obtainList = res.data.prop.content
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true;
        }
        console.log(e);
      }
    },
    onPopupBottomClose() {
      if(this.typeShowContent != 1){
        this.$emit("pbClose");
      }
    },
    //批量开启盲盒
    async onObtain() {
      this.showLoading = true
      try {
        const res = await open_box({
          num: this.seedNumber
        });
        this.close();
        this.showLoading = false
        this.showObtain = true;
        this.residueMh = res.data.residue
        this.lotteryContList = res.data.prize_list
      } catch (e) {
        console.log(112, e)
        this.showLoading = false
        this.$toast(e.data);
      }
    },
    close() {
      this.$emit("close");
    },
    //继续开启盲盒
    continues() {
      if (this.residueMh) {
        this.onObtainClose();
        this.$emit('continueBreak', 1)
      } else {
        this.$toast('暂无盲盒了，快去做任务吧~');
        this.close();
      }
    },
    onObtainClose() {
      this.$emit('continueBreak', 2)
      this.showObtain = false
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .van-loading {
  text-align: center;
}
.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}
.popupBottom {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.rewardDetail {
  width: 250px;
  padding: 10px 20px 0;
  border-radius: 20px;
  overflow: hidden;

  .sendIcon {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .font1 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .font2 {
    font-size: 13px;
    margin-bottom: 30px;
  }

  .font3 {
    font-size: 11px;
    color: #c5c5c5;
    margin-bottom: 10px;

  }
}

.rewards {
  .reward {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    .rewardIcon {
      position: absolute;
      left: 10px;
      top: 20px;
    }

    .rwardTitle {
      margin-top: 20px;
      font-size: 14px;
    }
  }

  .rwardList {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px;

    overflow-y: scroll;
    height: 400px;

    .rwardItem {
      margin-left: 10px;
      margin-bottom: 10px;
      width: 100px;
      position: relative;

      .itemImg {
        width: 100%;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .itemFont {
        width: 100%;
        text-align: center;
      }

      .sendIcon {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 12px;
        height: 12px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.obtion {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .obtionBgk {
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    padding: 20px;
    width: 330px;

    .obtionTop {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }

    .obtionBtm {
      .obtainList {
        overflow-y: scroll;
        height: 300px;

        .obtainItem {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          .itemImg {
            margin-right: 10px;
            width: 50px;
            height: 50px;
            border-radius: 10px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .itemFont {
            font-size: 15px;
          }
        }
      }

      .classBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .useBtn {
          width: 140px;
          border-radius: 20px;
          font-size: 14px;
          text-align: center;
          padding: 8px 0;
        }

        .btn1 {
          color: #000;
          background: #ededed;
          border: 1px solid #000;
        }

        .btn2 {
          color: #fff;
          background: #000;
          border: 1px solid #000;
        }
      }
    }
  }

}

.use {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .useBgk {
    width: 300px;
    border-radius: 12px;
    overflow: hidden;

    .useBgkTop {
      background: #fff;
      padding-bottom: 20px;

      .useTop {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 15px 20px;

        // justify-content: ;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }

        div {
          font-size: 13px;
        }
      }

      .useMh {
        width: 150px;
        height: 150px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .useBgkBtm {
      background: #ededed;
      padding: 30px 15px;
      margin-top: -1px;

      .useBtm {
        padding: 0 10px;

        .btmFont {
          padding: 0 20px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          text-align: center;
        }

        .stepper {
          padding: 0 20px;
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          .stepperFont {
            margin-right: 10px;
          }
        }

        .classBtn {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .useBtn {
            padding: 5px 45px;
            border-radius: 20px;
            font-size: 14px;
          }

          .btn1 {
            color: #000;
            background: #ededed;
            border: 1px solid #000;
          }

          .btn2 {
            color: #fff;
            background: #000;
            border: 1px solid #000;
          }
        }
      }
    }
  }


}</style>
