const rewardData = [
	{
		img: require(`../../../assets/img/yqPreheat/奖品-元气碎片+元石.png`),
		text: '3元气碎片+5元石',
		ms: ['活动结束后，工作人员统一发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-元气碎片+元石.png`),
		text: '5元气碎片+10元石',
		ms: ['活动结束后，工作人员统一发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-元气碎片+元石.png`),
		text: '6元气碎片+20元石',
		ms: ['活动结束后，工作人员统一发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-元气碎片+元石.png`),
		text: '8元气碎片+50元石',
		ms: ['活动结束后，工作人员统一发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-艾柱.png`),
		text: '艾柱1套+5元气碎片',
		ms: ['购买姜小竹元气硬件后赠送价值¥99艾柱一套', '工作人员将在姜小竹元气硬件发售结束后，为下单姜小竹且确认收货的用户发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-返现卡.png`),
		text: '姜小竹元气硬件5%返现+5元气碎片',
		ms: ['下单姜小竹返现5%', '工作人员将在姜小竹元气硬件发售结束后，为下单姜小竹且确认收货的用户发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-购买码.png`),
		text: '姜小竹元气硬件抢购权益卡1个+5元气碎片',
		ms: ['元气硬件权益卡为下单姜小竹唯一资格凭证', '工作人员将在姜小竹发售前，为中奖用户发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-本草纲目.png`),
		text: '本草纲目藏品+5元气碎片',
		ms: ['藏品随机属性1个', '活动结束后，工作人员统一发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-watch.png`),
		text: 'Applewatch+5元气',
		ms: ['Applewatch中奖用户须凭5个李时珍头像兑奖', '活动结束后，工作人员统一发放']
	},
	{
		img: require(`../../../assets/img/yqPreheat/奖品-拍立得.png`),
		text: '富士instax mini LiPlay拍立得有声相机+5元气',
		ms: ['拍立得中奖用户须凭5个李时珍头像兑奖','活动结束后，工作人员统一发放']
	},
]

export {
	rewardData
}
