<template>
  <div>
    <div class="yqPreheat">
      <div class="bgkTop">
        <div class="endTime">
          <div class="font">
            距离活动结束
          </div>
          <div class="time">
            {{ time }}
          </div>
        </div>
        <div class="gs">
          <img src="../../assets/img/yqPreheat/gs.png" alt="">
        </div>
        <div class="bgkDang">
          <div class="dangSz">
            <img src="../../assets/img/yqPreheat/sz.png" alt="">
          </div>
          <div class="dang">
            <img src="../../assets/img/yqPreheat/dang.png" alt="">
          </div>
        </div>
      </div>
      <div class="mhHdBgk">
        <div class="classPng">
          <div class="rwPng">
            <img src="../../assets/img/yqPreheat/rw.png" alt="">
          </div>
          
        <div class="rwTips">
          <div>任务一、二、三、四每位用户仅可完成一次</div>
          <div>任务五可以重复完成</div>
        </div>
          <div class="mhPng">
            <img src="../../assets/img/yqPreheat/mh.png" alt="">
          </div>
        </div>
        <div class="mhHdBgkBtm">
          <div class="openMhPng">
            <img @click="onOpenMh()" src="../../assets/img/yqPreheat/openMh.png" alt="">
          </div>
          <div class="mhSize" v-if="JSON.stringify(detail) !== '{}'">
            盲盒：{{ detail.prop.num }}个
          </div>
          <div class="mhFont">
            <div v-for="(item, index) in mhHdFont" :key="index" @click="onGoto(item.goto)">
              <div class="font">
                {{ item.name }}
              </div>
            </div>
          </div>
          <div class="hdList">
            <div class="hdItem" v-if="item.task" v-for="(item, index) in mhHdList" :key="index">
              <div class="hdItemLeft">
                <div class="hdListIcon">
                  <img :src="item.icon" alt="">
                </div>
                <div class="font">
                  <div class="font1">
                    {{ item.title1 }}
                  </div>
                  <div class="font2">
                    {{ item.title2 }}{{ item.task.reward }}
                  </div>
                </div>
              </div>
              <div :class="['fontBtn', !taskMhDetail.complete ? 'btnErr' : item.task.complete ? 'btnSuc' : 'btnErr']"
                @click="onMhHdList(item)">
                <span v-if="!taskMhDetail.complete" @click="prompt('请参加活动~')">
                  去解锁
                </span>
                <span v-else-if="item.funName == 'certification'">
                  <span v-if="!item.task.finished && !item.task.can_get" @click="toxz()">
                    去完成
                  </span>
                  <span v-if="!item.task.finished && item.task.can_get" @click="onReceive()">
                    去领奖
                  </span>
                  <span v-if="item.task.finished && !item.task.can_get">
                    已完成
                  </span>
                </span>
                <!-- <span v-else-if=""></span> -->
                <span v-else>
                  {{ item.task.complete ? '已完成' : '去完成' }}
                </span>
              </div>
            </div>
          </div>
          <div class="ewm">
            <div class="font">
              <div>
                扫码关注公众号
              </div>
              <div>
                参与推文转发获取元气盲盒
              </div>
            </div>
            <div class="ewmPng">
              <img src="../../assets/img/yqPreheat/ewm.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="bgmBtm">
        <div class="font">
          已参与人数：{{ detail.total_subscribed_count }}
        </div>
        <div class="ljcyPng" v-if="JSON.stringify(taskMhDetail) !== '{}'" @click="participated()">
          <img src="../../assets/img/yqPreheat/ljcy.png" alt="">
          <div class="ljcyFont">
            {{ taskMhDetail.complete ? '查看活动榜单' : '参与活动' }}
          </div>
        </div>
      </div>
    </div>
    <participatedSuc :show="popupParticipatedType" :num="participatedData.num" :title="participatedData.title"
      @blokFun="blokFunP($event)"></participatedSuc>
    <mh-use :show="popupMhUse" :mhNum="detail.prop.num" ref="mhUse" @continueBreak="onDetailContinue($event)"
      @close="onMhUseClose()" @pbClose="onShowMhUse()"></mh-use>
    <mh-detail :show="showReward" @close="onShowReward()" @openMh="onOpenMh()"></mh-detail>
    <friends :show="showFriends" @shareShow="onShare()" @close="onShowFriends()"></friends>
    <gzhPopup :show="showGzhPopup" :type="gzhPopupType" :nameFont="gzhPopupText" @close="showGzhPopup = false"
      :code="detail.user_join_code"></gzhPopup>
    <login :show="isShowLogin" @close="isShowLogin = false" @success="hasLogin"></login>
    <Share ref="Share" v-if="showShare" @close="onColse()" :show="showShare"></Share>
  </div>
</template>
<script>
import { subscribe, vitality_blind_box, cert } from "../../api/yqPreheat";
import Share from "./components/share";
import Login from "../../components/Login";
import participatedSuc from "./components/participatedSuc.vue";
import mhUse from "./components/mhUse.vue";
import mhDetail from "./components/mhDetail.vue";
import friends from "./components/friends.vue";
import gzhPopup from "./components/gzhPopup.vue";
import getMyMixins from './common/mixins.js'

export default {
  mixins: [getMyMixins],
  name: "yqPreheat",
  components: {
    participatedSuc,
    mhUse,
    mhDetail,
    Login,
    friends,
    gzhPopup,
    Share
  },
  data() {
    return {
      showGzhPopup: false,//是否展示公众号发送消息弹框
      showFriends: false, //是否展示邀请好友列表
      showReward: false, //是否展示盲盒明细
      popupParticipatedType: false,//是否展示参与成功弹框
      popupMhUse: false,//是否展示开启盲盒弹框
      isShowLogin: false,
      time: '',//结束时间
      mhSize: 0, //当前用户盲盒数量
      taskMhDetail: {}, //当前盲盒详细数据
      detail: {},//活动详细配置
      showShare: false, //海报是否显示
      gzhPopupText: '',//公众号发送修改
      participatedData: {//领取盲盒弹框
        num: 0,
        title: ''
      },
      gzhPopupType: '', //任务消息类型join_official_account 公众号发送口令 join_group 加入社群 join_dodo 加入dodo
      mhHdFont: [
        {
          name: '查看盲盒内容',
          goto: 'onShowContent',
        },
        {
          name: '查看邀请列表',
          goto: 'onShowFriends',
        },
        {
          name: '查看盲盒明细',
          goto: 'onShowReward',
        }
      ],
      mhHdList: [ //活动任务
        {
          title1: '完成实名认证',
          title2: '完成后盲盒+',
          icon: require("../../assets/img/yqPreheat/icon-sm.png"),
          type: false,
          funName: 'certification'
        },
        {
          title1: '微信公众号发送口令',
          title2: '完成后盲盒+',
          icon: require("../../assets/img/yqPreheat/icon-gzh.png"),
          type: false,
          funName: 'join_official_account'
        },
        {
          title1: '加入社群',
          title2: '完成后盲盒+',
          icon: require("../../assets/img/yqPreheat/icon-message.png"),
          type: false,
          funName: 'join_group'
        },
        {
          title1: '加入DODO社群发送口令',
          title2: '完成后盲盒+',
          icon: require("../../assets/img/yqPreheat/icon-dodo.png"),
          type: true,
          funName: 'join_dodo'
        },
        {
          title1: '邀请好友参与并完成实名认证',
          title2: '每有1个新用户好友参与并完成实名盲盒+',
          icon: require("../../assets/img/yqPreheat/icon-lx.png"),
          type: false,
          funName: 'subscribe_invite'
        }
      ]
    };
  },
  computed: {

  },
  async mounted() {
    await this.onDateil();
    this.onGetTime();
  },
  destroyed() {
  },

  created() {
  },
  watch: {},
  methods: {
    //提示
    prompt(e) {
      this.$toast(e)
    },
    //关闭海报
    onColse() {
      this.showShare = false;
    },
    //海报生成
    onShare() {
      this.showFriends = false
      this.showShare = true;
    },
    /**
     * 倒计时
     * @param {Number} endTime 结束时间
     * @param {Number} type 状态值
     */
    countDown(endTime, type) {
      var nowtime = new Date(); //获取当前时间
      var lefttime = endTime * 1000 - nowtime.getTime(), //距离结束时间的毫秒数
        leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)), //计算天数
        lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
        leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
        lefts = Math.floor((lefttime / 1000) % 60); //计算秒数

      if (leftd <= 0 && lefth <= 0 && leftm <= 0 && lefts <= 0) {
        return false;
      }
      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      if (type == 1) {
        return ` ${leftd}d:${timeAdd0(lefth)}h:${timeAdd0(leftm)}m`;
      }
    },
    //继续开
    onDetailContinue(e) {
      console.log(112, e)
      if (e == 1) {
        setTimeout(() => {
          this.popupMhUse = true
          this.onDateil();
          return false
        }, 500)
      } else {
        this.onDateil();

      }

    },
    //实名认证领取盲盒
    async onReceive() {
      try {
        const res = await cert({});
        this.participatedData.num = this.detail.task.certification.reward
        this.participatedData.title = '实名成功'
        this.popupParticipatedType = true
        this.onDateil();

      } catch (e) {
        this.$toast(`${e.data}`)
        console.log(e);
      }
    },
    //活动参与 预约
    async onActivity() {

    },
    //活动任务
    onMhHdList(e) {
      if (!this.taskMhDetail.complete) {
        this.$toast(`请先参加活动~`)
        return false
      }
      if (!localStorage.getItem('token')) {
        this.isShowLogin = true;
        return false
      }
      //已完成活动不可点击
      if (e.task.complete) {
        return false
      }
      this.gzhPopupType = e.funName
      switch (e.funName) {
        case 'join_official_account':
          this.gzhPopupText = '扫码加群/关注公众号'
          this.showGzhPopup = true
          break;
        case 'join_group':
          this.gzhPopupText = '加入社群'
          this.showGzhPopup = true
          break;
        case 'join_dodo':
          this.gzhPopupText = '加入dodo社群'
          this.showGzhPopup = true
          break;
        case 'subscribe_invite':
          this.onShare();
          break;
      }
    },
    onShowReward() {
      this.showReward = false
    },
    onShowFriends() {
      this.showFriends = false
    },
    onGoto(e) {
      if (!localStorage.getItem('token')) {
        this.isShowLogin = true;
        return false
      }
      switch (e) {
        case 'onShowContent':
          this.$refs.mhUse.onShowContent(1);
          break;
        case 'onShowFriends':
          this.showFriends = true
          break;
        case 'onShowReward':
          console.log(44, e)
          this.showReward = true
          break;
      }
    },
    //盲盒内容关闭后重新显示打开盲盒弹框
    onShowMhUse() {
      this.popupMhUse = true
    },
    hasLogin() {
      this.isShowLogin = false;
      this.onDateil();
    },
    //开启盲盒
    onOpenMh() {
      this.showReward = false
      if (!localStorage.getItem('token')) {
        this.isShowLogin = true;
        return false
      }
      if (this.detail.prop.num) {
        this.popupMhUse = true
      } else {
        this.$toast('您暂时还没有盲盒喔，快去做任务吧~')
      }
    },
    //立即参与回调
    blokFunP(e) {
      console.log(444, e)
      this.popupParticipatedType = false
    },
    //跳转APP并实名
    toxz() {
      // window.location.href = "https://appfile.relaverse.cn/m/download.html";
      if (!localStorage.getItem('token')) {
        this.isShowLogin = true;
        return false
      }
      let that = this;
      that.opening = true;
      window.location = "relax-town://pages/user/realName";
      that.timer = setTimeout(function () {
        //  未安装的情况
        that.opening = false;
        //  跳转app store
        let r = confirm("未安装APP? 是否去App store查看");
        if (r) {
          window.location = "https://appfile.relaverse.cn/m/download.html";
        }
      }, 5000);
    },
    //获取活动详情
    async onDateil() {
      try {
        const res = await vitality_blind_box({
          acctoken: localStorage.getItem('token')
        });
        // console.log(111, res)
        this.detail = res.data
        let obj = res.data.task
        //把活动任务赋值给自己的值里面
        for (let key in obj) {
          for (let i = 0; i < this.mhHdList.length; i++) {
            if (this.mhHdList[i].funName === key) {
              this.mhHdList[i].task = obj[key]
            } else if (key === 'subscribe') {
              this.taskMhDetail = obj[key];
              console.log(11223, this.taskMhDetail)
            }
          }
        }

        this.$forceUpdate()
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true;
        }
        console.log(e);
      }
    },
    //获取结束时间
    onGetTime() {
      this.timer = setInterval(() => {
        const time = this.countDown(Number(this.detail.subscribe_end_time), 1);
        if (!time) {
          clearInterval(this.timer);
          this.time = '活动已结束'
          // announceTime
        } else {
          this.time = time;
        }
      }, 1000);
    },
    //底部按钮点击
    async participated() {
      console.log(11, this.taskMhDetail)
      if (localStorage.getItem("token")) {
        if (this.taskMhDetail.complete) {
          this.toPage('yqPreheatRanking')
        } else {
          try {
            const res = await subscribe({});
            if (this.detail.task.subscribe.reward) {
              this.participatedData.num = this.detail.task.subscribe.reward
              this.participatedData.title = '参与成功'
              this.popupParticipatedType = true
            }
            this.onDateil();
          } catch (e) {
            this.$toast(`${e.data}`)
            console.log(e);
          }
        }
      } else {
        this.isShowLogin = true;
      }
    },
    //关闭开启盲盒弹框
    onMhUseClose() {
      this.popupMhUse = false;
    },
    toPage(name) {
      this.$router.push({
        name,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin bgkType($option: 0.5, $border: 3px) {
  border-radius: 5px;
  border: $border solid #fff;
  background: rgba(255, 255, 255, $option);
}

.yqPreheat {
  background: url(../../assets/img/yqPreheat/bg.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 3000px;
  margin-bottom: 100px;

  .bgkTop {
    position: relative;
    top: 500px;

    .endTime {
      display: flex;
      justify-content: center;
      align-items: center;

      .font {
        margin-right: 10px;
        font-size: 13px;
      }

      .time {
        padding: 2px 8px;
        font-size: 15px;
        @include bgkType(0.5, 1px);
      }
    }

    .gs {
      img {
        width: 100%;
        height: 280px;
      }
    }

    .bgkDang {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      position: relative;

      .dangSz {
        position: absolute;
        animation: move 10s linear infinite;
        top: -27px;
        width: 100px;
        height: 100px;

        img {
          width: 100%;
          height: 100%;
        }

        @keyframes move {
          from {
            transform: rotate(0);
          }

          to {
            transform: rotate(360deg);
          }
        }
      }

      .dang {
        img {
          width: 30px;
          height: 40px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.mhHdBgk {
  @include bgkType();
  width: 93%;
  top: 680px;
  position: relative;
  padding: 30px 0 0;
  margin: auto;

  .classPng {
    position: relative;
    .rwTips{
      position: absolute;
      width: 100%;
      top: 105px;
      font-size: 11px;
      color: #3e3e3e;
      div{
        text-align: center;
      }
    }
    .rwPng {
      width: 80%;
      height: 100px;
      margin: 0 auto 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mhPng {
      width: 80%;
      height: 280px;
      margin: -40px auto 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .mhHdBgkBtm {
    position: relative;
    z-index: 2;
    .openMhPng {
      width: 70%;
      height: 60px;
      margin: -50px auto 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mhSize {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }

    .mhFont {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .font {
        padding: 0 0 3px;
        border-bottom: 1px solid #000;
        font-size: 13px;
      }
    }

    .hdList {
      .hdItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 10px;

        .hdItemLeft {
          display: flex;
          align-items: center;

          .hdListIcon {
            width: 40px;
            height: 40px;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .font {
            .font1 {
              font-size: 12px;
              font-weight: 700;
            }

            .font2 {
              font-size: 12px;
              width: 150px;
            }
          }
        }

        .fontBtn {
          width: 90px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 30px;
          font-size: 13px;

        }

        .btnSuc {
          background: #303030;
          color: #fff;
        }

        .btnErr {
          background: #fff;
          color: #000;
        }
      }

    }

    .ewm {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 10px 0;

      .font {
        font-size: 12px;
        font-weight: 700;
      }

      .ewmPng {
        width: 70px;
        height: 70px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.bgmBtm {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: #fff;
  padding: 10px 0 0;
  z-index: 99;

  .font {
    text-align: center;
  }

  .ljcyPng {
    width: 70%;
    height: 90px;
    margin: auto;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .ljcyFont {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64%;
      color: #fff;
      font-size: 16px;
    }
  }
}</style>
