import http from './yqPreheathttp.js'


//预约 参与活动
export const subscribe = (data) => {
  return http.post('arrogant_game/vitality_blind_box/subscribe', data)
}

//实名认证领取盲盒
export const cert = (data) => {
  return http.post('arrogant_game/vitality_blind_box/cert', data)
}

//获取活动信息
export const vitality_blind_box = (data) => {
  return http.get('activity/vitality_blind_box.php', {
      params: data
  })
}

//获取我邀请人的列表
export const my_invite_list = (data) => {
  return http.get('arrogant_game/vitality_blind_box/my_invite_list', {
      params: data
  })
}

//获取排行信息
export const ranking_list = (data) => {
  return http.get('arrogant_game/vitality_blind_box/ranking_list', {
      params: data
  })
}

//获取盲盒收支记录
export const log = (data) => {
  return http.get('arrogant_game/vitality_blind_box/log', {
      params: data
  })
}

//开出的奖品总览
export const my_prize = (data) => {
  return http.get('/arrogant_game/vitality_blind_box/my_prize', {
      params: data
  })
}

//查看盲盒开启内容
export const log_detail = (data) => {
  return http.get('arrogant_game/vitality_blind_box/log_detail', {
      params: data
  })
}

//批量开启盲盒
export const open_box = (data) => {
  return http.post('arrogant_game/vitality_blind_box/open_box', data)
}

//记录在活动页呆的时间超过15秒的用户
export const seconds15_user = (data) => {
  return http.get('arrogant_game/vitality_blind_box/seconds15_user', {
    params: data
})
}