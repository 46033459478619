<template>
  <div class="mhUse">
    <van-popup class="popupBottom" @close="close()" v-model="show" position="bottom">
      <div class="rewards">
        <div class="reward">
          <div class="rewardIcon" @click="close()">
            <van-icon name="arrow-left" size="19" />
          </div>
          <div class="rwardTitle">
            邀请好友列表
          </div>
        </div>
        <div class="list">
          <div class="listItem" v-if="friendsList.length" v-for="(item, index) in friendsList" :key="index">
            <div class="itemLeft">
              <div class="itemPhoto">
                <img :src="item.avatar" alt="">
              </div>
              <div class="user">
                <div class="name">{{ item.username }}</div>
                <div class="photo">{{ item.mobile }}</div>
              </div>
            </div>
            <div class="itemRight" :style="item.certification ? 'color:#0070ff' : ''">
              {{ item.certification ? '已实名' : '未认证' }}
            </div>
          </div>
          <div class="noList">
            暂无数据~
          </div>
        </div>
        <div class="btn" @click="onShare()">
          继续邀请
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { my_invite_list } from "../../../api/yqPreheat";
import { Tabs, Tab } from "vant";
export default {
  data() {
    return {
      activeName: 1, //当前选择下标
      showDetails: false,//是否显示当前盲盒开启奖励内容
      showReward: false,//是否展示盲盒内容弹框
      showPrize: false, //是否显示奖品总览
      friendsList: [],
    };
  },
  props: ["show"],
  computed: {
  },
  components: { Tabs, Tab },
  async mounted() {
    this.onlist();
  },
  methods: {
    //继续邀请
    onShare(){
      this.$emit('shareShow')
    },
    //获取列表
    async onlist() {
      try {
        const res = await my_invite_list({
          acctoken: localStorage.getItem('token')
        });
        this.friendsList = res.data.data
      } catch (e) {
        this.$toast(`${e.data}`)
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
    onObtainClose() {
      this.showObtain = false
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-tabs--line .van-tabs__wrap {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
}

.popupBottom {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.reward {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-bottom: 26px;

  .rewardIcon {
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .rwardTitle {
    margin-top: 20px;
    font-size: 14px;
  }
}

.list {
  padding: 20px 65px 40px 30px;

  overflow-y: scroll;
  height: 400px;
  position:relative;
  .noList{
    position:absolute;
    left: 43%;
    width: 70px;
  }
  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .itemLeft {
      display: flex;
      align-items: center;

      .fontName {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 700;
      }

      .itemPhoto {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .user {
        .name {
          font-size: 14px;
          margin-bottom: 5px;
        }

        .photo {
          font-size: 12px;
        }
      }
    }

    .itemRight {}
  }
}

.btn {
  width: 90%;
  border-radius: 20px;
  background: #000;
  padding: 10px 0;
  color: #fff;
  margin: 20px auto;
  text-align: center;
}</style>
