import http from './http.js'

// 服贸会列表
export const list = (data) => {
  return http.post('/api/v1.Ciftis/ciftis_1list', data)
}

// 排名
export const rank = (data) => {
  return http.post('/api/v1.Ciftis/get_openid_ranking', data)
}

// 获取openID
export const getOpenidApi = (data) => {
  return http.post('/api/v1.Ciftis/getWxOpenid', data)
}

// 获取微信信息
export const getWeiUser = (data) => {
  return http.post('/api/v1.Ciftis/getUserInfoByCode', data)
}
