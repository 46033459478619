<template>
  <div class="fu-mao-hui">
    <div class="up">
      <div class="text-center">
        <img :src="require('../../assets/img/fu-logo-top.png')" width="250px" height="100%" />
      </div>
      <div class="title text-center">
        打卡2022年服贸会中医药展区
      </div>
      <div class="up-desc border">
        <div class="m-b-15">
          中国国际服务贸易交易会由国家商务部和北京市人民政府共同主办，是中国三大展会之一。
        </div>
        <div>
          2022年服贸会健康卫生专题展中医药展区由北京市中医管理局主办，北京民族医药文化研究促进会承办，以“发展传统医药，推动人类健康共同体”为主题，展示“科创中医药、数字中医药、民族医药与现代化、中医在双奥、中医元宇宙”等中医药服务贸易新业态，线上线下共有来自近20个省自治区直辖市的81家机构参展。通过举办北京中医药国际消费日、中医药服务贸易四五品牌矩阵新媒体传播、中医药NFT数字藏品发布等活动，多视角展示各类型中医药机构在理论研究、技术服务、产品创新、文化创意等方面成果。
        </div>
      </div>
    </div>
    <div class="text-center bold f-15 white m-b-20">领取为服贸会中医药展区观众准备的惊喜吧！</div>
    <div class="down">
      <div class="company m-b-15 border" v-for="item in specialList" :key="item.id">
        <div class="text-center f-14 bold m-b-15">
          {{item.title}}
        </div>
        <div class="company-desc m-b-20">
          <div class="company-left">
            <img :src="item.image_url" width="80px" />
          </div>
          <div class="f-12 company-right opacity-9">
            {{item.content}}
          </div>
        </div>
        <van-button round type="primary" block @click="follow(item.jump)" color="linear-gradient(0deg, #EF7900 0%, #EF6000 100%)">
          <span class="bold">{{item.button}}</span>
        </van-button>
      </div>
      <div class="border all">
        <div class="text-center f-15 bold m-b-15">
          其它参加打卡活动单位
        </div>
        <div class="text-center f-12 bold m-b-15 opacity-9">
          点击LOGO跳转公众号领取福利
        </div>
        <div class="logos">
          <div class="logo-item m-t-20" @click="follow(item.jump)" v-for="item in normalList" :key="item.id">
            <div class="logo-item-icon">
              <img :src="item.image_url" width="100%" height="100%" />
            </div>
            <div class="text-center f-11 opacity-9">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="fixed">
        <div class="user" v-if="openid">
          <img :src="avatar" class="avatar" />
          <div class="info">
            <div class="f-14 bold">{{nickname}}</div>
            <div class="f-11">第{{rank}}位参观者</div>
          </div>
        </div>
        <van-button round type="primary" block :style="{'width': openid ? '40%' : '100%'}" @click="createPoster"
          color="linear-gradient(0deg, #EF7900 0%, #EF6000 100%)">
          <span class="bold">生成分享海报</span>
        </van-button>
      </div>
    </div>
    <!-- 在浏览器时提醒 -->
    <van-popup v-model="isShow" closeable close-icon="close" round>
      <div class="browser">
        <div class="f-16 bold text-center m-b-10">请长按保存二维码</div>
        <div class="f-16 text-center m-b-20">在微信客户端扫码参与</div>
        <div class="code m-b-20">
          <vue-qr :text="url" :margin="10" :size="140"></vue-qr>
        </div>
        <div class="f-14 text-center">参与用户将获赠纪念版数字藏品</div>
      </div>
    </van-popup>
    <van-popup v-model="isShowPoster">
      <div class="poster">
        <div class="create">
          <div class="create-up">
            <div class="create-up-text">
              <div class="text-center f-13 bold flex" style="justify-content: center;">
                <img :src="require('../../assets/img/fu-logo-3.png')" width="22" height="23"
                  style="margin-right:10px;" />
                <img :src="require('../../assets/img/fu-logo-4.png')" width="22" height="23"
                  style="margin-right:10px;" />
                <span style="text-shadow: 0px 1px 5px rgba(138,63,0,0.34);">
                  中国国际服务贸易交易会中医药展区
                </span>
              </div>
              <div class="flex create-up-user m-t-25 m-b-25" style="justify-content: center;">
                <img :src="avatar" width="35px" height="35px" class="create-up-user-avatar" />
                <span class="f-17">{{nickname}}</span>
              </div>
              <div class="text-center bold f-17">
                我是2022中国国际服务贸易交易会<br>
                中医药展区第{{rank}}位打卡的参观者
              </div>
              <div class="text-center f-13 m-t-20">
                我正在为
              </div>
              <div class="create-up-wrap f-17 bold text-center">
                发展传统医药，推动人类健康共同体
              </div>
              <div class="f-19 bold text-center">助力！</div>
            </div>
            <img class="poster-bg" :src="require('../../assets/img/fu-poster-bg.png')" />
          </div>
          <div class="create-down">
            <div class="">
              <div class="f-12 bold m-b-5" style="color: #14161B">扫描二维码关注后 点击底部菜单</div>
              <div class="f-12 bold" style="color: #43494F">领取活动专属福利包</div>
            </div>
            <div class="">
              <img src="../../assets/img/fu-poster-code.jpg" width="57px" height="57px"/>
            </div>
          </div>
        </div>
        <div class="real">
          <img :src="poster" width="100%" v-if="poster"/>
        </div>
      </div>
      <div>
        <div class="save flex m-t-20" @click="downLoad">
          <van-icon name="back-top" size="24px" style="transform: rotate(180deg);" />
        </div>
        <div class="text-center white f-12 m-t-5">长按图片识别二维码或保存到手机</div>
      </div>

    </van-popup>
  </div>
</template>

<script>
  import vueQr from 'vue-qr'
  import html2canvas from 'html2canvas'
  import {list,rank,getOpenidApi,getWeiUser} from '../../api/fumaohui.js'
  import qs from 'qs'
  export default {
    components: {
      vueQr
    },
    data() {
      return {
        isShow: false,
        isShowPoster: false,
        url: window.location.href,
        poster: '',
        specialList: [],
        normalList: [],
        openid: '',
        nickname: localStorage.getItem('weiNickname') || '',
        avatar: localStorage.getItem('weiAvatar') || '',
        rank: 0,
        openid: localStorage.getItem('openid') || ''
      }
    },
    async created() {
      if (this.isWeXin() && !localStorage.getItem('weiCode')) {
        // 静默授权
        this.getOauth()
      } else if (this.isWeXin() && this.$route.query.code){
        // 将code传递给后端
        try{
          if (localStorage.getItem('hasGetWei')) {
            await this.getUserInfo()
          } else {
            await getOpenidApi(qs.stringify({code: this.$route.query.code}))
          }
        } catch(err) {
          console.log(err)
        }
      }
      this.getList()
      if (localStorage.getItem('openid')) {
        this.getRank()
      }
    },
    methods: {
      async getList() {
        try{
          const res = await list()
          this.specialList = res.data.filter(item => item.type)
          this.normalList = res.data.filter(item => !item.type)
        } catch(err) {
          console.log(err)
          this.$toast(err.data)
        }
      },
      async getRank() {
        try{
          const res = await rank(qs.stringify({openid: this.openid}))
          this.rank = res.data
        } catch(err) {
          console.log((err))
        }
      },
      getOauth(oauthway = 'snsapi_base') {
        // snsapi_userinfo,STATE
        if (oauthway == 'snsapi_userinfo') {
          localStorage.setItem('hasGetWei',true)
        }
        const url = encodeURIComponent(window.location.href)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx23f5377e96c511e2&redirect_uri=${url}&response_type=code&scope=${oauthway}&state=fumaohui#wechat_redirect`
      },
      isWeXin() {
        const ua = navigator.userAgent.toLowerCase()
        const isWeixin = ua.indexOf('micromessenger') !== -1
        return isWeixin
      },
      follow(url) {
        if (!this.isWeXin()) {
          this.isShow = true
          return
        }
        window.location.href = url
      },
      async getUserInfo () {
        try{
          const res = await getWeiUser(qs.stringify({code: this.$route.query.code}))
          console.log(res)
          this.nickname = res.data.nickname
          this.avatar = res.data.headimgurl
          localStorage.setItem('weiNickname', res.data.nickname)
          localStorage.setItem('weiAvatar',res.data.headimgurl)
          this.openid = res.data.openid
          localStorage.setItem('openid', this.openid)
        } catch(err) {
          console.log(err)
        }
      },
      async createPoster() {
        if (!this.isWeXin()) {
          this.isShow = true
          return
        }
        if (!localStorage.getItem('hasGetWei')) {
          this.getOauth('snsapi_userinfo')
          return
        }
        this.isShowPoster = true
        this.$toast.loading({
          duration: 0,
          message: '正在生成海报',
          forbidClick: true,
        })
        setTimeout(() => {
          html2canvas(document.querySelector('.create'), {
            backgroundColor: null, // null 表示设置背景为透明色
            useCORS: true, // 是否尝试使用CORS从服务器加载图像
            allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
            taintTest: true, // 是否在渲染前测试图片
            scale: 3, // dpr比列
            scrollY: 0 // 截屏时页面滚动，造成截屏图片不全或空白
          }).then(canvas => {
            this.poster = canvas.toDataURL('image/png')
            this.$toast.clear()
          }).catch(e => {
            console.log(e)
            this.$toast.clear()
            this.$toast.fail('生成海报失败')
          })
        }, 100)
      },
      downLoad() {
        // const base64ToBlob = (code) => {
        //   let contentType = code.split(':')[1];
        //   console.log(contentType)
        //   let raw = window.atob(code);
        //   let rawLength = raw.length;
        //   let uInt8Array = new Uint8Array(rawLength);
        //   for (let i = 0; i < rawLength; ++i) {
        //     uInt8Array[i] = raw.charCodeAt(i);
        //   }
        //   return new Blob([uInt8Array], {
        //     type: contentType
        //   });
        // }

        // let aLink = document.createElement('a');
        // let blob = base64ToBlob(this.poster); //new Blob([content]);

        // let evt = document.createEvent("HTMLEvents");
        // evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        // aLink.download = 'poster';
        // aLink.href = URL.createObjectURL(blob);
        // aLink.dispatchEvent(new MouseEvent('click', {
        //   bubbles: true,
        //   cancelable: true,
        //   view: window
        // })); //兼容火狐
        const a = document.createElement('a')
                a.href = this.poster
                a.setAttribute('download', 'chart-download')
                a.click()
      }
    }
  }
</script>

<style scope lang="scss">
  .fu-mao-hui {
    background-color: #D06B00;

    .border {
      border: 1px solid #FFFFFF;
      border-radius: 10px;
      padding: 20px 14px;
      background: #FFFFFF;
      box-shadow: 0px 1px 13px 0px rgba(196, 104, 0, 0.19);
      color: #000000;
      text-align: justify;
      line-height: 20px;
    }

    .opacity-9 {
      opacity: 0.9;
    }

    .up {
      background: url(../../assets/img/fu-bg-up.png) no-repeat;
      background-size: contain;
      padding: 22px 0;

      .title {
        font-size: 26px;
        font-family: SourceHanSansCN;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 20px;
        text-shadow: 0px 1px 5px rgba(138, 63, 0, 0.34);
        margin: 25px 0;
      }

      .up-desc {
        margin: 0 14px;
        line-height: 20px;
        font-size: 12px;
        text-align: justify;
      }
    }

    .down {
      padding: 0 14px 14px;

      .company {
        .company-desc {
          display: flex;
        }

        .company-left {
          width: 80px;
          margin-right: 15px;
        }

        .company-right {
          width: calc(100% - 95px);
        }
      }

      .all {
        padding: 25px 15px;

        .logos {
          display: flex;
          flex-wrap: wrap;

          .logo-item {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              margin-top: 0;
            }

            .logo-item-icon {
              width: 60px;
              height: 60px;
              background: #FFCCB1;
              margin-bottom: 10px;
              border-radius: 50%;
              overflow: hidden;
            }
          }
        }
      }
    }

    .bottom {
      height: 70px;

      .fixed {
        position: fixed;
        width: 100%;
        background: white;
        bottom: 0;
        padding: 13px 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .user {
          width: 50%;
          display: flex;
          align-items: center;

          .avatar {
            width: 35px;
            height: 35px;
            margin-right: 7px;
            border-radius: 50%;
          }

          .info {
            width: calc(100% - 42px)
          }
        }
      }
    }

    .browser {
      width: 285px;
      padding: 35px;
      box-sizing: border-box;
      color: black;

      .code {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .poster {
      position: relative;
      width: 315px;

      .create {
        width: 100%;
        overflow: hidden;
        border-radius: 10px;
        position: absolute;

        .create-up {
          height: 350px;
          position: relative;

          .create-up-text {
            position: relative;
            z-index: 1;
            color: white;
            padding: 30px 0;

            .create-up-user {
              margin: 30px auto;

              .create-up-user-avatar {
                border: 1px solid #FFFFFF;
                border-radius: 50%;
                margin-right: 10px;
              }
            }

            .create-up-wrap {
              line-height: 19px;
              text-shadow: 0px 1px 1px rgba(138, 63, 0, 0.34);
              margin: 35px 0 25px;
            }
          }

          .poster-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .create-down {
          padding: 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #FFFFFF;
        }
      }

      .real {
        position: relative;
        z-index: 2;
        min-height: 417px;
      }
    }

    .save {
      width: 45px;
      height: 45px;
      background: #F4F4F6;
      border-radius: 50%;
      justify-content: center;
      margin: 25px auto 0;
    }

    /deep/.van-popup {
      background-color: transparent;
    }
  }
</style>
