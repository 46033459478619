<template>
  <div>
    <van-pull-refresh v-model="isLoading" loosing-text="下拉即可刷新" pulling-text="释放即可刷新" loading-text="加载中"
      @refresh="onRefresh">
      <div class="cont">
        <div class="headerBgk">
          <div class="icon">
            <div class="backIcon icons" @click="onBack()">
              <img src="../../assets/img/sleepActivity/back.png" alt="" />
            </div>
          </div>
          <div class="guizePage">
            <div class="guizeLeft">
              活动时间：{{detail.activity_start_time | dateFormat('day')}} - {{detail.activity_end_time | dateFormat('day')}}
            </div>
            <div class="guizeLeft" @click="onShowFriends()">
              邀请记录 >
            </div>
          </div>

        </div>
        <div class="bottomBgk">
          <div class="switchBottom">
            <div class="ranking" v-show="!switchType">
              <div class="rankingTop">
                <div>活动排名</div>
                <div>用户昵称</div>
                <div>邀请人数</div>
              </div>
              <div class="rank_list">
                <div class="list-item" v-for="(item, index) in list" :key="index">
                  <div class="ranking items">{{ item.order }}</div>
                  <div class="user items">
                    <div class="avatars">
                      <img class="avatarImg" :src="item.avatar" alt="">
                      <div class="crown" v-if="index === 0">
                        <img src="../../assets/img/yqPreheat/crown1.png" alt="">
                      </div>
                      <div class="crown" v-if="index === 1">
                        <img src="../../assets/img/yqPreheat/crown2.png" alt="">
                      </div>
                      <div class="crown" v-if="index === 2">
                        <img src="../../assets/img/yqPreheat/crown3.png" alt="">
                      </div>
                    </div>
                    <div class="userName">{{ item.username }}</div>
                  </div>
                  <div class="value items">{{ item.total }}</div>
                </div>
                <div class="notList">没有更多了~</div>
              </div>
            </div>
            <div v-show="switchType">
              <van-loading type="circular" color="#2c2bff" />
            </div>
          </div>
        </div>
        <!-- 悬浮按钮 -->
        <div class="btnBgk" v-if="JSON.stringify(rankingInfo) != '{}'">
          <div class="btnWh" v-if="rankingInfo.rankingData">
            <div class="top">
              <!-- {{rankingInfo.rankingData.disparity}} -->
              <div v-if="rankingInfo.rankingData.disparity">距离上一名还差：{{ rankingInfo.rankingData.disparity }}</div>
              <div @click="onShare()">继续邀请好友 ></div>
            </div>
            <div class="bottom" v-if="rankingInfo.rankingData.my_order">
              <div class="rankingUser bottoms">{{ rankingInfo.rankingData.my_order.order }}</div>
              <div class="userInfo bottoms">
                <div class="userAvatar">
                  <!--   -->
                  <img v-if="user.avatar != '0'" :src="rankingInfo.rankingData.my_order.avatar" alt="" />
                  <img v-else src="../../assets/img/user.png" alt="" />
                </div>
                <div class="userName">
                  {{ rankingInfo.rankingData.my_order.username }}
                </div>
              </div>
              <div class="rankingUsers bottoms">{{ rankingInfo.rankingData.my_order.total }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <friends :show="showFriends" @shareShow="onShare()" @close="onShowFriendClose()"></friends>
    <Share ref="Share" v-if="showShare" @close="onColse()" :show="showShare"></Share>
    <login :show="isShowLogin" @close="isShowLogin = false" @success="loginSuccess"></login>
  </div>
</template>
<script>
import Share from "./components/share.vue";
import { ranking_list,vitality_blind_box } from "../../api/yqPreheat";
import friends from "./components/friends.vue";
import { mapMutations, mapState } from "vuex";
import Login from "../../components/Login";
export default {
  components: {
    Share,
    Login,
    friends
  },
  data() {
    return {
      btnData: {
        btnType: "", //按钮状态 1未参与 2已参与 3未开始 4已结束
        btnFont: "参与预约", //按钮文案
      },
      switchs: "week", //当前选中的是周还是总 默认周排行榜
      list: [], //排名数组
      rankingInfo: {}, //排名信息
      configure: {}, //info信息
      switchType: false, //周总排行榜切换状态
      showShare: false, //海报是否显示
      isLoading: false, //下拉刷新
      isShowLogin: false, //登录
      showFriends: false, //是否展示邀请好友列表
      detail: {}//活动信息

    };
  },
  created() {
  },
  mounted() {
    this.onDateil();
    this.onLeaderboard();
  },
  filters: {
    //时间戳转化
    dateFormat(value, type) {
      if (!value) return "";
      let time = new Date(value * 1000);

      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      if (type == "day") {
        return timeAdd0(y) + "." + timeAdd0(m) + "." + timeAdd0(d);
      }
    },
  },
  destroyed() { },
  watch: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
      invited_code: (state) => state.invited_code,
      invited_ids: (state) => state.invited_ids,
    }),
  },
  methods: {
    ...mapMutations({
      vxSetUser: "setUser",
      vxSetSignIn: "setSignIn",
    }),
    onShowFriends() {
      this.showFriends = true
    },
    onShowFriendClose(){
      this.showFriends = false
    },
    //登录成功
    loginSuccess() {
      this.isShowLogin = false;
      this.onLeaderboard();
    },
    /**
     * name  跳转路径
     * type  跳转参数
     */
    gotoPage(name, type) {
      this.$router.push({
        path: name,
        query: {
          type: type,
        },
      });
    },
    onBack() {
      this.$router.go(-1);
    },
    //获取排行榜
    async onLeaderboard() {
      try {
        const res = await ranking_list({
          acctoken: localStorage.getItem('token')
        });
        if (res.data.list instanceof Array) {
          this.list = res.data.list;
        } else {
          this.list = Object.values(res.data.list);
        }
        this.$set(this.rankingInfo, 'rankingData', res.data)
        console.log(112, this.rankingInfo)
        this.$forceUpdate()
      } catch (e) {
        this.onError(e);
      }
    },
    //获取活动详情
    async onDateil() {
      try {
        const res = await vitality_blind_box({
          acctoken: localStorage.getItem('token')
        });
        this.detail = res.data
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true;
        }
        console.log(e);
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.onLeaderboard();
        this.isLoading = false;
        clearInterval(this.timer);
      }, 1000);
    },

    //关闭海报
    onColse() {
      this.showShare = false;
    },
    //海报生成
    onShare() {
      this.showFriends = false
      this.showShare = true;
    },
    /**
     * 错误失败操作
     * @param {object} e 错误信息
     */
    onError(e) {
      console.log(e);
      if (e.code === 201) {
        this.isShowLogin = true;
      } else {
        this.$toast(e.data);
      }
      this.loadings = false;
    },
    //时间戳转化
    dateFormat(value) {
      if (!value) return "";
      let time = new Date(Math.floor(value * 1000));
      function timeAdd0(str) {
        if (str < 10) {
          str = "0" + str;
        }
        return str;
      }
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      let h = time.getHours();
      let mm = time.getMinutes();
      let s = time.getSeconds();
      return `${timeAdd0(y)}.${timeAdd0(m)}.${timeAdd0(d)}`
    },
  },
};
</script>
<style></style>
<style scoped lang="scss">
.cont {
  min-height: 100vh;
  padding-bottom: 135px;
  background: #4b97d2 !important;

}
/deep/.van-loading__text{
  color: #fff!important;
}
/deep/ .van-loading {
  text-align: center;
}

/deep/ .van-overlay {
  z-index: 5 !important;
}

/deep/ .van-pull-refresh {
  background: #4b97d2 !important;
}

/deep/ .van-pull-refresh__head {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  color: #fff !important;
}

@mixin btn_Fun($url) {
  .btnFont {
    background-image: url($url);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      color: #fff;
      font-size: 20px;
      /* font-weight: 700; */
      letter-spacing: 2px;
      margin-top: -8px;
    }
  }
}

// 超出显示...单文本
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}

//设置背景图
@mixin bgk_Fun($url) {
  background-image: url($url);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.headerBgk {
  height: 450px;
  position: relative;
  @include bgk_Fun($url: "../../assets/img/yqPreheat/rankingBgk.png");

  .icon {
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    position: fixed;
    z-index: 3;
    top: 0;
    width: 100%;

    .icons {
      width: 30px;
      height: 30px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .guizePage {
    position: absolute;
    color: #fff;
    bottom: 180px;
    font-size: 13px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.btnBgk {
  position: fixed;
  bottom: 0;
  width: 100%;
  // @include bgk_Fun($url: "../../assets/img/sleepActivity/bottom.png");
  background: #fff;
  z-index: 3;

  .btnWh {
    width: 100%;
    height: 90px;
    padding: 7px 30px 0;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      div {
        color: #000;
        font-size: 11px;
      }

      div:last-child {
        color: #000;
        font-size: 11px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      padding: 10px 0;
      @include bgk_Fun($url: "../../assets/img/yqPreheat/rankingLjcy.png");
      height: 70px;

      .bottoms {
        width: 33%;
        text-align: center;
        margin-top: -15px;
      }

      .userInfo {
        display: flex;
        align-items: center;

        .userName {
          color: #ffffff;
          font-size: 11px;
          width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .userAvatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;
          background: #ffffff;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .rankingUser {
        color: #ffffff;
      }

      .rankingUsers {
        color: #ffffff;
      }
    }
  }
}

.bottomBgk {
  width: 90%;
  margin: -165px auto 0;
  position: relative;
  z-index: 2;

  .switchClass {
    height: 40px;
    font-weight: 700;
    color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 8px 0;
    height: 100%;

    .switchWeek {
      font-size: 15px;
    }

    .switchTotal {
      font-size: 15px;
    }
  }

  .switchBottom {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px 15px 10px;
    width: 100%;
    margin: -1px auto 0;
  }

  .activity {
    color: #2c2bff !important;
  }

  .ranking {
      .rankingTop {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        color: #81c8ff;
        font-size: 12px;

        div {
          width: 33%;
          text-align: center;
          font-weight: 700;
        }
      }

      .rank_list {
        height: 400px;
        overflow-y: scroll;
        margin-bottom: 100px;
        .notList {
          text-align: center;
          margin-top: 20px;
          color: #3c3c3c;
          font-size: 12px;
        }

        .list-item {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-bottom: 2px solid #e7e7e7;
          padding: 15px 0;

          .items {
            width: 33%;
            text-align: center;
          }

          .ranking {
            font-size: 14px;
            color: #000;
            font-weight: 700;
          }

          .user {
            display: flex;
            align-items: center;

            .avatars {
              
              margin-right: 10px;
              position: relative;
              .avatarImg {
                width: 30px;
              height: 30px;
              border-radius: 50%;
              overflow: hidden;
              }
              
              .crown{
                position: absolute;
                right: -6px;
                top: -12px;
                width: 20px;
                height: 20px;
                img{
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .userName {
              color: #000;
              font-size: 11px;
              width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }

          .value {
            color: #000;
            font-size: 14px;
          }
        }
      }
    }

  

  .activityBgk {
    padding: 5px 0;

    .activityTime {
      background: #dcdcdc;
      text-align: center;
      border-radius: 20px;
      width: 75%;
      margin: auto;
      padding: 10px 0;
      font-size: 11px;
      padding: 3px;
    }
  }
}

.bottomBgkWeek {
  @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingWeekly.png");
}

.bottomBgkTotal {
  @include bgk_Fun($url: "../../assets/img/sleepActivity/rankingTotal.png");
}

.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
    height: 80px;
  }
}
</style>
