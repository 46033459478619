<template>
  <div class="mhUse">
    <van-popup class="popupBottom" @close="close()" v-model="show" position="bottom">
      <div class="rewards">
        <div class="reward">
          <div class="rewardIcon" @click="close()">
            <van-icon name="arrow-left" size="19" />
          </div>
          <div class="rwardTitle">
            盲盒明细
          </div>
        </div>
        <tabs v-model="activeName" @click="onClick()" color="#00a1ff">
          <tab title="获得盲盒">
            <div class="list">
              <div class="listItem" v-if="mhList" v-for="(item, index) in mhList" :key="index">
                <div class="itemFont">
                  <div class="fontName">{{ item.name }}</div>
                  <div class="fontTime">{{ item.create_time }}</div>
                </div>
                <div class="fontNum">
                  +{{ item.num }}
                </div>
              </div>
              <div class="noList" v-else>
                暂无数据~
              </div>
            </div>
            <div class="btm">
              <div class="btmFont">
                已获得：{{mhTotal}}
              </div>
              <div class="btmBtn" @click="onOpenMh()">
                去开启
              </div>
            </div>
          </tab>
          <tab title="获得奖品">
            <div class="list">
              <div class="listItem" v-if="my_prize.length" v-for="(item, index) in my_prize" :key="index">
                <div class="itemFont">
                  <div class="fontName">{{ item.name }}*{{item.num}}</div>
                  <div class="fontTime">{{ item.create_time }}</div>
                </div>
                <div class="fontNum" @click="onShowDetails(item)">
                  查看内容
                </div>
              </div>
              <div class="noList" v-if="!my_prize.length">
                暂无数据~
              </div>
            </div>
            <div class="btm">
              <div class="btmFont">
                已使用：{{mhTotal}}
              </div>
              <div class="btmBtn" @click="onShowPrize()">
                查看总览
              </div>
            </div>
          </tab>
        </tabs>
      </div>

    </van-popup>
    <van-popup class="popupBottom" v-model="showDetails" position="bottom">
      <div class="rewards">
        <div class="reward">
          <div class="rewardIcon" @click="showDetails = false">
            <van-icon name="arrow-left" size="19" />
          </div>
          <div class="rwardTitle">
            查看内容
          </div>
        </div>
        <div class="obtainList">
          <div class="obtainItem" v-for="(item, index) in obtainList" :key="index">
            <div class="obtainLeft">
              <!-- <div class="itemImg">
                <img :src="item.img" alt="">
              </div> -->
              <div class="itemFont">
                {{ index.split('，')[0] }} 
              </div>
            </div>
            <div>
              x{{item}}
            </div>
          </div>
        </div>
        <div class="obtainTips">
          活动结束后，工作人员联系中奖用户验资发放。其中，姜小竹返现卡和艾柱免单券，工作人员将在近期姜小竹发售期间，为下单姜小竹且中奖用户发放。硬件购买码为下单姜小竹的唯一资格凭证，将在姜小竹发售期间核奖。
        </div>
      </div>
    </van-popup>x
    <van-popup class="popupBottom" v-model="showPrize" position="bottom">
      <div class="rewards">
        <div class="reward">
          <div class="rewardIcon" @click="showPrize = false">
            <van-icon name="arrow-left" size="19" />
          </div>
          <div class="rwardTitle">
            奖品总览
          </div>
        </div>
        <div class="obtainList">
          <div class="obtainItem" v-for="(item, index) in myPrize" :key="index">
            <div class="obtainLeft">
              <!-- <div class="itemImg">
                <img :src="item.img" alt="">
              </div> -->
              <div class="itemFont">
                {{ item.name.split('，')[0] }} 
              </div>
            </div>
            <div>
              x{{item.num}}
            </div>
          </div>
        </div>
        <div class="obtainTips">
          活动结束后，工作人员联系中奖用户验资发放。其中，姜小竹返现卡和艾柱免单券，工作人员将在近期姜小竹发售期间，为下单姜小竹且中奖用户发放。硬件购买码为下单姜小竹的唯一资格凭证，将在姜小竹发售期间核奖。
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { log,log_detail,my_prize,vitality_blind_box } from "../../../api/yqPreheat";
import { Tabs, Tab } from "vant";
export default {
  data() {
    return {
      mhTotal: 0,//盲盒获取总数量
      activeName: 0, //当前选择下标
      showDetails: false,//是否显示当前盲盒开启奖励内容
      showReward: false,//是否展示盲盒内容弹框
      showPrize:false, //是否显示奖品总览
      mhList: [],//盲盒收支记录
      my_prize:[],//奖品记录
      obtainList: [],//活动获取奖励列表
      myPrize: [],//我的中奖总览
    };
  },
  props: ["show"],
  computed: {
  },
  components: { Tabs, Tab },
  async mounted() {
    this.onGetLog(1);
    // this.onDateil();
  },
  methods: {
    onOpenMh(){
      this.$emit('openMh')
    },
    //获取活动详情
    async onDateil() {
      try {
        const res = await vitality_blind_box({
          acctoken: localStorage.getItem('token')
        });
        this.obtainList = res.data.prop.content
      } catch (e) {
        if (e.code === 201) {
          this.isShowLogin = true;
        }
        console.log(e);
      }
    },
    //查看开启盲盒内容
    async onShowDetails(e){
      const res = await log_detail({
          acctoken: localStorage.getItem('token'),
          id:e.id
        });
      this.obtainList = res.data
      this.showDetails = true
    },
    //查看总览
    async onShowPrize(){
      try {
        const res = await my_prize({acctoken: localStorage.getItem('token')});
        this.myPrize = res.data
        this.showPrize = true
      } catch (e) {
        this.$toast(`${e.data}`)
        console.log(e);
      }
    },
    //切换下标
    onClick(e){
      console.log(this.activeName === 0)
      if(this.activeName === 0){
        this.onGetLog(1);
      } else {
        this.onGetLog(2);
      }
    },
    async onGetLog(e){
      try {
        const res = await log({
          acctoken: localStorage.getItem('token'),
          type:e
        });
        if(e == 1){
          this.mhList = res.data.data
        } else {
          this.my_prize = res.data.data
        }
        this.mhTotal = res.data.total
      } catch (e) {
        this.$toast(`${e.data}`)
        console.log(e);
      }
    },
    close() {
      this.$emit("close");
    },
    onObtainClose() {
      this.showObtain = false
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/.van-tabs--line .van-tabs__wrap {
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;
}

.popupBottom {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.reward {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-bottom: 26px;

  .rewardIcon {
    position: absolute;
    left: 10px;
    top: 20px;
  }

  .rwardTitle {
    margin-top: 20px;
    font-size: 14px;
  }
}

.obtainList {
  overflow-y: scroll;
  height: 300px;
  padding: 0 20px 20px;
  .obtainItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .obtainLeft {
      display: flex;
      align-items: center;
      width: 80%;
      .itemImg {
        margin-right: 10px;
        width: 80px;
        height: 80px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .itemFont {
        font-size: 13px;
      }
    }

  }
}
.obtainTips{
  padding: 20px 40px;
  font-size: 13px;
}

.list {
  padding: 20px 65px 40px 30px;

  overflow-y: scroll;
  height: 400px;
  position: relative;
  .noList{
    position: absolute;
    left: 43%;
  }
  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .itemFont {
      .fontName {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 700;
      }

      .fontTime {
        font-size: 12px;
      }
    }

    .fontNum {}
  }
}

.btm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #ededed;
  padding: 20px 30px;

  .btmFont {
    font-weight: 700;
    font-size: 17px;
  }

  .btmBtn {
    padding: 5px 25px;
    border-radius: 20px;
    background: #000;
    color: #fff;
    font-size: 17px;
  }
}</style>
