import axios from "axios";
import { seconds15_user } from "../../../api/yqPreheat";
// const baseUrl = require('../../../../site')
export default {
	data() {
		return {
		}
	},
	created() {
	},
	async mounted() {
		setTimeout(()=>{
			this.onUserPoint();
		}, 15000)
	},
	methods: {
		//获取埋点 记录在活动页呆的时间超过15秒的用户
		async onUserPoint() {
			// // 添加请求拦截器
			// axios.interceptors.request.use(function (config) {
			// 	// 在发送请求前的操作
			// 	// 判断是否存在token,如果存在将每个页面header添加token
			// 	// if (localStorage.getItem('token')) {
			// 	// 	config.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
			// 	// }
			// 	return config
			// }, function (error) {
			// })
			// axios
			// 	.post(`${baseUrl.yqPreheatDomain}arrogant_game/vitality_blind_box/seconds15_user`, {
			// 		acctoken: `${localStorage.getItem('token')}`
			// 	})
			// 	.then((success) => {
			// 		if (res.code == 200) {

			// 		} else {
			// 			this.$toast.clear();
			// 			this.$toast(res.message);
			// 		}
			// 	})
			// 	.catch((error) => {
			// 		console.log(error);
			// 	});

			try {
				const res = await seconds15_user({
					acctoken:`Bearer ${localStorage.getItem('token')}`
				});
			} catch (e) {
				console.log(e);
			}
		},
	}
}
