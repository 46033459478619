import axios from 'axios'
import {
  Toast
} from 'vant'
const baseUrl = require('../../site')

const http = axios.create({
  timeout: 15000,
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://api.relaverse.cn/' : 'http://api.relaverse.cn/'
  baseURL: baseUrl.yqPreheatDomain,
})

http.interceptors.request.use(config => {
  if (localStorage.getItem('token') && typeof config.data === 'object') {
    config.data.acctoken = localStorage.getItem('token')
  }
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})
http.interceptors.response.use(res => {
  if (res.data.code === 200) {
    return res.data
  }
  return Promise.reject(res.data)
}, error => {
  console.log(error)
  Toast.fail('服务器出现小问题，请通知社群成员解决')
  const e = {
    data: '服务器出现小问题，请通知社群成员解决'
  }
  return Promise.reject(e)
})

export default http
