<template>
  <div class="share">
    <van-overlay z-index="100" :show="show" @click="close" :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }">
      <div class="heads">
        <div class="backs">
          <van-icon name="arrow-left" class="white" size="18" @click="back" />
        </div>
      </div>
      <van-loading type="circular" size="50" color="#FFFFFF" v-if="loadingIns" class="loding" vertical>
        海报生成中...
      </van-loading>
      <div class="wrapper">
        <div class="block" @click.stop>
          <div class="shareHtml" id="poster" ref="poster">
            <div class="bgkss">
              <!-- <img class="bgksInt" src="../../../assets/img/lszSolicitation/bg2.png" alt=""> -->
              <img class="bgksInt" src="../../../assets/img/yqPreheat/poster.png" alt="">

              <div class="qrCode">
                <div class="codeFont">
                  <div class="codeImg">
                    <img v-if="user.avatar != '0'" :src="user.avatar" alt="">
                    <img v-else src="../../../assets/img/user.png" alt="" />
                  </div>
                  <div class="font">
                    <div class="fontName">{{user.nickname}}</div>
                    <div class="fontTips">
                      <img src="../../../assets/img/yqPreheat/hbText.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="qrs">
                  <vue-qr :text="url" class="qr-code" :margin="0" :logoScale="0.2" :size="50"></vue-qr>
                  <!-- <img src="" alt=""> -->
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div v-if="img" class="poster_img">
        <img  @touchstart="getTouchStart(img)" @touchend="getTouchEnd"  :src="img" alt="" />
      </div>
      <div class="tips">长按图片转发或保存</div>
      <div class="btm" @click.stop="copy">
        <div class="btmCode">
          <div class="btmFont">
            我的邀请码：
          </div>
          <div class="code">
            {{user.code}}
          </div>
        </div>
        <div class="btmCopyBtn" ref="copy">
          复制
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
// import store from "../store";
import {
  configure
} from "../../../api/lszSolicitation";
import vueQr from "vue-qr";
import html2canvas from "html2canvas";
import Clipboard from 'clipboard'
import {
  mapMutations,
  mapState
} from "vuex";

export default {
  props: ["show", "collectionUrl"],

  components: {
    vueQr
  },
  data() {
    return {
      img: "",
      url: "",
      configure: {},
      loadingIns: true,
      type: 2,
      longClick:0,
      code:'XFASDFAS'
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
      isLogin: (state) => state.token,
    }),
  },
  async mounted() {
    document.addEventListener("UniAppJSBridgeReady", () => {
        this.getTouchStart();
      });
    this.loadingIns = true
    await this.onConfigure();
    this.createPoster();
  },
  methods: {
    copy() {
      let copy = this.$refs.copy;
      const clipBoard = new Clipboard(copy, {
        text: () => {
          return this.user.code;
        },
      });
      clipBoard.on("success", (e) => {
        console.log(e);
        this.$toast("复制成功");
      });
      clipBoard.on("error", (err) => {
        console.log(err);
        this.$toast.fail("复制失败");
      });
    },
    getTouchStart(img) {
      this.timer = setTimeout(() => {
        uni.postMessage({
          data: {
            action: img,
            invitationCode: "",
          },
        });
      }, 1000);
    },
    getTouchEnd() {
        clearTimeout(this.timer);
      },
    back(){
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    //获取授权藏品图片
    async onConfigure() {
      try {
        const res = await configure({
          acctoken: this.isLogin
        });
        this.configure = res.data
        console.log(4444, this.configure)
      } catch (e) {
        this.onError(e)
      }
    },
    createPoster() {
      this.url = `${window.location.origin}/#/yqPreheatIndex?invited_id=${this.user.id}`;
      setTimeout(() => {
        const dom = document.getElementById('poster')
        html2canvas(dom, {
          width: dom.offsetWidth, //设置canvas尺寸与所截图尺寸相同，防止白边
          height: dom.offsetHeight - 12, //防止白边
          backgroundColor: null, // null 表示设置背景为透明色
          useCORS: true, // 是否尝试使用CORS从服务器加载图像
          allowTaint: true, // 允许跨域（图片跨域相关），服务器也需要做相应的图片跨域处理
          taintTest: true, // 是否在渲染前测试图片
          scale: 4, // dpr比列
          useCORS: true,
          scrollY: 0, // 截屏时页面滚动，造成截屏图片不全或空白
        })
          .then(canvas => {
            const img = canvas.toDataURL("image/png");
            this.img = img;
            this.loadingIns = false
          })
          .catch(e => {
            console.log(e);
            // this.$toast.fail("??????");
          });
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btm{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  position: absolute;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 17px 37px;
  .btmCode{
    display: flex;
    align-items: center;
    .btmFont{
      margin-right: 10px;
    }
    .code{
      font-size: 15px;
      font-weight: 700;
    }
  }
  .btmCopyBtn{
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 20px;
    background: #000;
    color: #fff;
  }
}
.heads {
  .backs {
    width: 27px;
    height: 27px;
    background: #3c87a5;
    border: 1px solid #fff;
    border-radius: 50%;
    position: fixed;
    left: 13px;
    top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
}

.share {
  .block {
    position: relative;
  }

  .shareHtml {
    position: absolute;
    top: -10000000px;
    // top: 0;
    left: 50%;
    width: 240px;
    transform: translate(-50%, -50%);

    .hedimg {
      width: 250px;
      height: 400px;
      // background: pink;
      border-radius: 5px 5px 0px 0px;
    }

    .bgkss {

      .bgksInt {
        // position: absolute;
        width: 100%;
        height: 400px;
        z-index: -1;
        top:0;
      }

      .shareUser {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 70px;

        .userImg {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          color: #ffffff;
          font-size: 14px;

          .name {
            color: #ffffff;
            font-weight: 700;
            font-size: 15px;
          }
        }
      }

      .sharePartner {
        color: #ffffff;
        width: 93%;
        margin: auto;
        text-align: center;
        line-height: 20px;
        font-weight:700;
        .partner {
          font-size: 11px;
          text-align: center;
        }
      }

      .collectionImg {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .collectionCont {
        margin: 10px auto 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 11px;
        text-align: center;
        // img{
        //   width: 26px;
        //   height: 15px;
        // }
      }

      .qrCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px 10px;
        background: #ffffff;
        margin-top: -10px;
        height: 80px;
        .codeFont {
          display: flex;
          align-items: center;
          .codeImg{
            width: 40px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .font {
            color: #979999;
            .fontName{
              color: #000;
              margin-bottom: 3px;
              font-size: 11px;
            }
            .fontTips{
              // width: 100px;
              // font-size: 11px;
              img{
                width: 100px;
                height: 18px;
              }
            }
          }
        }

        .qrs {
          width: 38px;
          height: 40px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;

          .qr-code {
            width: 100%;
            height: 100%;
            background: #ffffff;
          }
        }
      }
    }

    .bgks {
      background: #000;
      border-radius: 10px;

      .shareUser {
        display: flex;
        align-items: center;
        padding: 10px;
        margin-bottom: 10px;

        .userImg {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .userName {
          color: #979999;
          font-size: 14px;

          .name {
            color: #ffffff;
            font-weight: 700;
            font-size: 15px;
          }
        }
      }

      .sharePartner {
        color: #ffffff;
        font-weight: 700;
        line-height: 25px;
        padding: 10px;

        div {
          font-size: 16px;
        }
      }

      .textFonts {
        color: #979999;
        font-size: 11px;
        padding: 10px;
      }

      .collectionImg {
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px auto;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .qrCode {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        background: #ffffff;
        margin-top: -10px;
        .codeFont {
          display: flex;
          align-items: center;
          .codeImg{
            width: 35px;
            height: 35px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .font {
            color: #979999;
            .fontName{
              margin-bottom: 3px;
              font-size: 12px;
            }
            .fontTips{
              width: 100px;
              font-size: 12px;
            }
          }
        }

        .qrs {
          width: 60px;
          height: 60px;
          background: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;

          .qr-code {
            width: 90%;
            height: 90%;
            background: #ffffff;
          }
        }
      }
    }

  }

  .tips {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    bottom: 11%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .poster_img {
    height: 100vh;
    width: 100%;
    img {
      width: 300px;
      height: 545px;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 27px 11px #004256;
    }
  }
}
</style>
