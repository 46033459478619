<template>
  <div class="participated">
    <van-overlay z-index="100" :show="show" @click="close(1)" :custom-style="{ background: 'rgba(0, 0, 0, 0.9)' }">
      <div class="success">
        <div>
          <div class="font1">
          {{title}}
        </div>
        <div class="font2">您已获得元气盲盒+{{num}}</div>
        <div class="sucImg">
          <img src="../../../assets/img/yqPreheat/mh1.png" alt="">
        </div>
        <div class="sucBtn" @click.stop="close(2)">
          邀请好友掉落更多盲盒
        </div>
        <div class="font3">
          <div>
            邀新人数排名前50名用户可额外获得
          </div>
          <div>
            元气盲盒叠加+姜小竹硬件首期发售优先购资格
          </div>
        </div>
        <div @click="close(1)">

        </div>
        </div>
       
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props: ["show", 'title', 'num'],
  computed: {
  },
  async mounted() {
    
  },
  methods: {
    close(e){
      this.$emit("blokFun", e);
    }
  }
};
</script>

<style lang="scss" scoped>
.success{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .font1{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #fff;
  }
  .font2{
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
  .sucImg{
    width: 230px;
    height: 230px;
    margin: 20px auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .sucBtn{
    padding: 10px 10px;
    background: #fff;
    color: #000;
    width: 70%;
    font-size: 14px;
    border-radius: 20px;
    text-align: center;
    margin: auto;
  }
  .font3{
    color: #fff;
    margin: 20px 40px;
    div{
    font-size: 12px;
    text-align: center;
    }
  }
}
</style>
